/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('//cdn.shopify.com/s/files/1/2150/4805/t/3/assets/open-sans-v15-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'),
       url('//cdn.shopify.com/s/files/1/2150/4805/t/3/assets/open-sans-v15-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('//cdn.shopify.com/s/files/1/2150/4805/t/3/assets/open-sans-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('//cdn.shopify.com/s/files/1/2150/4805/t/3/assets/open-sans-v15-latin-300.woff') format('woff'), /* Modern Browsers */
       url('//cdn.shopify.com/s/files/1/2150/4805/t/3/assets/open-sans-v15-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('//cdn.shopify.com/s/files/1/2150/4805/t/3/assets/open-sans-v15-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('//cdn.shopify.com/s/files/1/2150/4805/t/3/assets/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('//cdn.shopify.com/s/files/1/2150/4805/t/3/assets/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('//cdn.shopify.com/s/files/1/2150/4805/t/3/assets/open-sans-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('//cdn.shopify.com/s/files/1/2150/4805/t/3/assets/open-sans-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('//cdn.shopify.com/s/files/1/2150/4805/t/3/assets/open-sans-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('//cdn.shopify.com/s/files/1/2150/4805/t/3/assets/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('//cdn.shopify.com/s/files/1/2150/4805/t/3/assets/open-sans-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('//cdn.shopify.com/s/files/1/2150/4805/t/3/assets/open-sans-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('//cdn.shopify.com/s/files/1/2150/4805/t/3/assets/open-sans-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('//cdn.shopify.com/s/files/1/2150/4805/t/3/assets/open-sans-v15-latin-700.woff') format('woff'), /* Modern Browsers */
       url('//cdn.shopify.com/s/files/1/2150/4805/t/3/assets/open-sans-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('//cdn.shopify.com/s/files/1/2150/4805/t/3/assets/open-sans-v15-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}